
import ProductFunctions from '~/mixins/ProductFunctions'

export default {
  mixins: [ProductFunctions],
  props: {
    data: {
      type: Object,
      required: true,
    },

    textColor: {
      type: Boolean,
      default: false,
    },
  },
}
