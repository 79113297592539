import { render, staticRenderFns } from "./ProductSlider.vue?vue&type=template&id=ce5c2d7e&scoped=true&"
import script from "./ProductSlider.vue?vue&type=script&lang=js&"
export * from "./ProductSlider.vue?vue&type=script&lang=js&"
import style0 from "./ProductSlider.vue?vue&type=style&index=0&id=ce5c2d7e&prod&lang=scss&scoped=true&"
import style1 from "./ProductSlider.vue?vue&type=style&index=1&id=ce5c2d7e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce5c2d7e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductSliderItem: require('/vercel/path0/components/recommendations/ProductSliderItem.vue').default,Container: require('/vercel/path0/components/general/Container.vue').default,SelectableBgColor: require('/vercel/path0/components/general/SelectableBgColor.vue').default})
