
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      productSwiperOptions: {
        slidesPerView: 1,
        speed: 500,
        loop: true,
        autoplay: this.data.products.length > 1,
        pagination: {
          el: '.product-swiper-pagination',
          clickable: true,
        },

        navigation: {
          nextEl: '.product-swiper-btn-next',
        },
      },
    }
  },
}
